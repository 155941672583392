(function () {
  var script = document.createElement('script');
  script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-domain-script', 'f0e2525c-ab2b-4e3a-bef4-3eff3bc818c0-test');
  document.getElementsByTagName('head')[0].appendChild(script);
})();
function OptanonWrapper() {
  function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return true;
    }
  }

  function reloadOTBanner() {
    var otConsentSdk = document.getElementById('onetrust-consent-sdk');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (window.OneTrust != null) {
      OneTrust.Init();

      setTimeout(function () {
        OneTrust.LoadBanner();

        var toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

        for (var i = 0; i < toggleDisplay.length; i++) {
          toggleDisplay[i].onclick = function (event) {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }
}

(function(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=fCj_XrHtknRqKCdz-TqlOQ&gtm_preview=env-3&gtm_cookies_win=x';
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-WXGDDHZN');
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  'user_category':window.userType,
  'user_id': window.userId
});
